import Pusher from "pusher-js";

export default defineNuxtPlugin({
  name: "echo",
  setup() {
    var pusher = new Pusher("8a2c00e581e6c3332952", {
      cluster: "eu",
    });

    return {
      provide: {
        puhser: pusher,
      },
    };
  },
});
