<script setup lang="ts">
import { z } from "zod";
import type { CreateTaskData } from "../../entities/out/create_task_data";
import type {
  AddressComponent,
  Geometry,
} from "../../entities/geocoding_response";
import type { Task } from "../../entities/tasks";
import type { ApplicationEvents } from "../../composables/emitter";
const isModallVisible = defineModel<boolean>();
const toast = useToast();
const { t } = useI18n();
const { createTask, editTask, selectTask } = useTeamsStore();
const emitter = useEmitter();
const form = ref();
const state = ref<Partial<CreateTaskData>>({
  type: "delivery",
});
const selectedAddress = ref<{
  formatted_address: string;
  geometry?: Geometry;
  address_components?: AddressComponent[];
}>();

const loading = ref(false);

// Current task
const taskRef = ref<Task>();
let eventName: keyof ApplicationEvents;
emitter.on("task:edit", (payload) => {
  taskRef.value = selectTask(payload.taskId);
  isModallVisible.value = true;
  eventName = "task:edit";
});
emitter.on("task:duplicate", (payload) => {
  taskRef.value = selectTask(payload.taskId);
  isModallVisible.value = true;
  eventName = "task:duplicate";
});

emitter.on("task:create", () => {
  isModallVisible.value = true;
  eventName = "task:create";
});

emitter.on("task:show", () => {
  isModallVisible.value = true;
  eventName = "task:show";
});

emitter.on("task:createOnTheMap", async (payload) => {
  isModallVisible.value = true;
  eventName = "task:createOnTheMap";
  const latlng = `${payload.lat},${payload.lng}`;
  const result = await getGeocodingResponseByLatLng(latlng);
  console.log(result);
  const adressesComponents = result[0];
  state.value.receiver_location_longitude = payload.lng;
  state.value.receiver_location_latitude = payload.lat;
  selectedAddress.value = {
    formatted_address: "",
    address_components: adressesComponents?.address_components,
  };
});

const updateStateWithAddress = (addressComponents: AddressComponent[]) => {
  console.log(addressComponents);
  const { city, country, postalCode } =
    extractAddressDetails(addressComponents);

  state.value.receiver_city = city;
  state.value.receiver_country = country;
  state.value.receiver_postal_code = postalCode;
};

const updateStateWithReceiverAddress = (formatted_address: string) => {
  state.value.receiver_address = formatted_address;
};

// Constante pour mettre à jour l'état avec l'emplacement du destinataire
const updateStateWithReceiverLocation = (geometry: Geometry) => {
  state.value.receiver_location_longitude = geometry.location.lng;
  state.value.receiver_location_latitude = geometry.location.lat;
};

// Fonction de veille
watch(selectedAddress, () => {
  const address = selectedAddress.value;
  if (address) {
    if (address.address_components && address.address_components.length > 0) {
      updateStateWithAddress(address.address_components);
    }
    if (address.formatted_address) {
      updateStateWithReceiverAddress(address.formatted_address);
    }
    if (address.geometry) {
      updateStateWithReceiverLocation(address.geometry);
    }
  }
});

watch(taskRef, () => {
  const task = taskRef.value;

  if (!task) {
    return;
  }

  const createTask: CreateTaskData = {
    description: task.description || undefined,
    notes: task.notes,
    receiver_address: task.receiver_address,
    receiver_location_latitude: task.receiver_location.coordinates[1],
    receiver_location_longitude: task.receiver_location.coordinates[0],
    receiver_email: task.receiver_email,
    receiver_phone: task.receiver_phone,
    receiver_name: task.receiver_name,
    receiver_city: task.receiver_city,
    receiver_country: task.receiver_country,
    receiver_postal_code: task.receiver_postal_code,
    type: task.type,
    user_id: task.user_id,
  };

  selectedAddress.value = {
    formatted_address: task.receiver_address,
    address_components: [],
  };
  // if we are editing, we should send the task id
  state.value = createTask;
  console.log(state.value);
});

const schema = z.object({
  description: z.string().optional(),
  notes: z.string(),
  receiver_address: z.string(),
  receiver_phone: z.string(),
  type: z.string(),
  receiver_name: z.string(),
  receiver_location_latitude: z.number(),
  receiver_location_longitude: z.number(),
  receiver_email: z.string().email(t("invalidEmail")),
});

const _createTask = async () => {
  loading.value = true;
  try {
    if (taskRef.value && eventName == "task:edit") {
      await editTask(state.value, taskRef.value.id);
    } else if (
      eventName == "task:create" ||
      eventName == "task:duplicate" ||
      eventName == "task:createOnTheMap"
    ) {
      await createTask(state.value);
    }
    isModallVisible.value = false;
    toast.add({ title: t("saved") });
  } catch (error) {
    const result = convertBackendErrorToFormMsg(error);
    form.value.setErrors(result);
  } finally {
    loading.value = false;
  }
};
const onSubmit = () => {
  _createTask();
};
</script>

<template>
  <UModal v-model="isModallVisible">
    <UForm
      ref="form"
      v-disable-all="eventName == 'task:show'"
      :schema="schema"
      :state="state"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          {{
            eventName == "task:edit"
              ? `${$t("editTask")} #${taskRef?.number}`
              : $t("createTask")
          }}
        </template>

        <div class="flex">
          <!-- Description -->
          <CoreInputForm
            v-model="state.description"
            class="flex-1"
            name="description"
            label="Description"
            place-holder="The description of the task to be done"
            type="textarea"
          ></CoreInputForm>
          <!--End  Description -->
        </div>

        <div class="flex">
          <!-- Users -->
          <CoreForm class="flex-1" name="user_id" :label="$t('agent')">
            <SelectUsers v-model="state.user_id"></SelectUsers>
          </CoreForm>
          <!-- End Users -->

          <!-- Email -->
          <CoreInputForm
            v-model="state.receiver_email"
            class="flex-1"
            name="receiver_email"
            label="Email"
            place-holder="Receiver email"
            type="textarea"
          ></CoreInputForm>
          <!--End  Email -->
        </div>

        <div class="flex">
          <!-- type -->
          <CoreForm class="flex-1" name="type" :label="$t('type')">
            <CoreSelect
              v-model="state.type"
              :options="[
                { value: 'delivery', text: 'Delivery' },
                { value: 'pickup', text: 'Pickup' },
              ]"
            ></CoreSelect>
          </CoreForm>

          <!-- End type -->

          <!-- Receiver phone-->
          <CoreInputForm
            v-model="state.receiver_phone"
            class="flex-1"
            :label="$t('phoneNumber')"
            type="text"
            name="receiver_phone"
          ></CoreInputForm>
          <!-- End Receiver phone-->
        </div>
        <!-- Receiver name -->
        <div class="flex">
          <CoreInputForm
            v-model="state.receiver_name"
            :label="$t('receiverName')"
            type="text"
            name="receiver_name"
            class="w-full"
          ></CoreInputForm>
        </div>
        <!-- End Receiver name -->
        <div class="flex">
          <!-- Receiver address-->
          <CoreAddressAutoComplete
            v-model="selectedAddress"
            :label="$t('receiverAddress')"
            name="receiver_address"
          ></CoreAddressAutoComplete>
          <!-- End receiver address-->
        </div>

        <div class="flex">
          <!-- Longitude-->
          <CoreInputForm
            v-model="state.receiver_location_longitude"
            class="flex-1"
            :label="$t('receiverLongtitude')"
            type="number"
            name="receiver_location_longitude"
          ></CoreInputForm>
          <!-- End  Longitude-->

          <!-- Latitude-->
          <CoreInputForm
            v-model="state.receiver_location_latitude"
            class="flex-1"
            :label="$t('receiverLatitude')"
            type="number"
            name="receiver_location_latitude"
          ></CoreInputForm>
          <!-- End Latitude-->
        </div>
        <div class="flex">
          <!-- Notes-->
          <CoreInputForm
            v-model="state.notes"
            class="flex-1"
            :label="$t('notes')"
            type="textarea"
            name="notes"
          ></CoreInputForm>
          <!-- End  Notes-->
        </div>
        <template #footer>
          <!-- Create task button -->
          <CoreButton
            :loading="loading"
            color="primary"
            type="submit"
            class="m-1"
            >{{ $t("save") }}</CoreButton
          >
          <!-- End create task button -->

          <!-- Cancel  button -->
          <CoreButton
            disaled="false"
            class="m-1"
            color="gray"
            @click.prevent="isModallVisible = false"
            >{{ $t("cancel") }}</CoreButton
          >
          <!-- End Cancel  button -->
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
