import type { AgentInfos } from "../entities/agent_infos";
import { useIntervalFn } from "@vueuse/core";

export const useTeamMembersStore = defineStore("teamMembersStore", () => {
  const { $api } = useNuxtApp();
  const members = ref<AgentInfos[]>([]);
  const loadAgentInfo = async () => {
    members.value = await $api("/management/agents");
  };

  const removeMemberFromCurrentTeam = async (userId: string) => {
    await $api(`/management/users/${userId}`, { method: "Delete" });
    const index: number = members.value.findIndex((t) => t.user_id == userId);
    console.log(index);
    if (index > -1) {
      members.value.splice(index, 1);
    }
  };

  const onlineAgents = computed(() =>
    members.value.filter((t) => t.status == "online").map(agentsTab),
  );
  const offlineAgents = computed(() =>
    members.value.filter((t) => t.status == "offline").map(agentsTab),
  );
  const busyAgents = computed(() =>
    members.value.filter((t) => t.status == "busy").map(agentsTab),
  );

  const agentsTab = (agent: AgentInfos) => {
    return {
      name: agent.user.name,
      id: agent.user.id,
      email: agent.user.email,
    };
  };

  watch(members, () => {
    for (let index = 0; index < members.value.length; index++) {
      const agentInfo: AgentInfos = members.value[index];
      const difference = differenceInMinutes(
        new Date(),
        new Date(agentInfo.last_active_at),
      );
      if (difference > 2) {
        agentInfo.status = "offline";
        continue;
      }

      if (agentInfo.active_tasks_count > 0) {
        agentInfo.status = "busy";
      } else {
        agentInfo.status = "online";
      }
    }
  });

  onMounted(() => {
    // Load agent info every 1 minutes
    useIntervalFn(
      () => {
        const isLoggedIn = useAuth().loggedIn.value;
        const isVerified = useAuth().user.value?.has_verified_email;
        if (isLoggedIn && isVerified) {
          loadAgentInfo();
        }
      },
      60000,
      { immediate: true },
    );
  });

  return {
    loadAgentInfo,
    removeMemberFromCurrentTeam,
    members,
    onlineAgents,
    busyAgents,
    offlineAgents,
  };
});
