<script setup lang="ts">
const { fetchUserInfo } = useAuth();
const cookieAccessToken = useCookie("auth:token");

onMounted(async () => {
  watch(
    cookieAccessToken,
    async () => {
      if (!cookieAccessToken.value) {
        return;
      }
      await fetchUserInfo();
      await nextTick();
      navigateTo("/");
    },
    { immediate: true },
  );
});
const { user } = useAuth();
const { $api } = useNuxtApp();

const resendActivationLink = () => {
  $api("/email/verification-notification", { method: "post" }).then(() =>
    console.log("email sent"),
  );
};
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <UAlert
        v-if="user && !user.has_verified_email"
        :actions="[
          {
            variant: 'solid',
            color: 'primary',
            label: $t('resendActivationLink'),
            onclick: () => resendActivationLink(),
          },
        ]"
        :title="$t('activateYourAccount')"
        :description="$t('activationMsg')"
      >
      </UAlert>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
    <UNotifications />
    <ModalTask></ModalTask>
    <ModalTeam></ModalTeam>
    <ModalUser></ModalUser>
    <ModalEditTaskFields></ModalEditTaskFields>
    <ModalConfirmation>{{ $t("areYouSureDeleteTask") }} </ModalConfirmation>
    <ModalDeleteTask></ModalDeleteTask>
    <ModalDeleteTeamMember></ModalDeleteTeamMember>
    <ModalDeleteTeam></ModalDeleteTeam>
    <ModalSubscribe></ModalSubscribe>
  </div>
</template>
